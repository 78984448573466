import { Button, ButtonProps } from "@chakra-ui/react";

export default function VASMButton(props: ButtonProps) {
    return (
        <Button
            transition={'0.4s all'}
            variant={'default'}
            _hover={{
                boxShadow: '0px 0px 14px -8px #b9b9b9b0',
            }}
            _active={{
                boxShadow: '0px 0px 14px -4px #b9b9b9b0'
            }}
            color={'white'}
            border={'1px solid #51515142'}
            fontSize={'14px'}
            {...props}
        >
            {props.children}
        </Button>
    )
}