import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Spinner,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function Error() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState<string>("SERVER_ERROR");

    useEffect(() => {
        const code = searchParams.get('code');
        setStatus(code || "SERVER_ERROR");

        setTimeout(() => {
            navigate('/');
        }, 10000);
    }, [searchParams]);

    function ShowStatus({ status }: { status: string }) {
        if (status === "DUPLICATE") {
            return (
                <Stack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                    <Heading fontSize={'2xl'} textAlign={'center'}>
                        ❌ Github Account Already Connected
                    </Heading>
                    <Text fontSize={'lg'} color={'rgba(255,255,255,0.6)'} textAlign={'center'}>
                        Your github account is already connected with another VASM Account.
                    </Text>
                </Stack>
            )
        }
        else {
            return (
                <Stack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                    <Heading fontSize={'2xl'} textAlign={'center'} >
                        ☹️ Something went wrong
                    </Heading>
                    <Text fontSize={'lg'} color={'rgba(255,255,255,0.6)'} textAlign={'center'}>
                        Please login again and re-try your request. If the error persists contact us at support@vasm.dev
                    </Text>
                </Stack>
            )
        }
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <ShowStatus
                    status={status}
                />
            </Stack>
        </Flex>
    )
}