import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Home from "../pages/home";
import NotFound from "../pages/404";
import SignIn from "../pages/sign-in";
import SignUp from "../pages/sign-up";
import Dashboard from "../pages/workspace";
import Verify from "../pages/verify";
import Layout from "../components/shared/layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../store/user-reducer";
import ProjectDashboard from "../pages/project-dashboard";
import Error from "../pages/error";
import { refreshToken } from "../store/auth-reducer";
import { UnknownAction } from "redux";

export default function VasmRouter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const refresh = searchParams.get('refresh');
    const authString = localStorage.getItem("auth");
    const auth = JSON.parse(authString === "" || authString === null ? "{}" : authString);

    if (auth === null || auth === undefined || auth.id === undefined) {
      navigate('/sign-in');
    } else {
      dispatch(loginSuccess(auth));
      if (refresh) {
        dispatch(refreshToken() as unknown as UnknownAction)
        searchParams.delete('refresh');
      }
      const rLink = location.pathname === "/" ? "/workspace" : location.pathname + location.search;
      navigate(rLink);
    }
  }, []);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/verify/:token" element={<Verify />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<NotFound />} />

      <Route path={'/workspace'} element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="project/:id" element={<ProjectDashboard />} />
      </Route>
    </Routes>
  )
}