import { Box, Button, Flex, Heading, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Select, Show, Spinner, Stack, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GrStatusGoodSmall } from "react-icons/gr";
import { useNavigate, useParams } from "react-router-dom";
import { GET, POST } from "../utils/api-client.util";
import LoadingOverlay from "../components/overlays/loading-overlay";
import ItemLoader from "../components/overlays/item-loader";
import Iframe from "../components/iframe/iframe";
import { BsChatSquareQuote, BsChevronDown } from "react-icons/bs";
import { RiFileShredLine, RiLogoutCircleRLine, RiRestartLine, RiShutDownLine } from "react-icons/ri";
import { AllRepoDropdown } from "../components/dropdowns/all-repo-dropdown";
import VASMButton from "../components/buttons/vasm-button";
import { FaGithub } from "react-icons/fa";
import { API_HOST } from "../constants";
import { useSelector } from "react-redux";

export default function ProjectDashboard() {
    const toast = useToast();
    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [connectionStatus, setConnectionStatus] = useState<string>('LOADING');

    const fetchDetails = async () => {
        setLoading(true);
        const request = await GET(`/agent/projects/${id}/details`);
        if (request.status !== "success" || request === undefined) navigate('/not-found');
        setLoading(false);
        setProject({ ...request.data });
    };

    useEffect(() => {
        fetchDetails();
    }, [id]);

    useEffect(() => {
        (async () => {
            setConnectionStatus('LOADING');
            const cRequest = await GET('/agent/github/is-connected');
            if (cRequest === undefined)
                setConnectionStatus('NOTCONNECTED')
            else if (cRequest.status !== "success")
                setConnectionStatus('NOTCONNECTED');
            else
                setConnectionStatus('CONNECTED');
        })()
    }, []);

    return (
        <Stack w={'full'}>
            {
                loading ?
                    (
                        <ItemLoader text="Loading Project Details" />
                    )
                    :
                    (
                        project.repo_url ?
                            (
                                <ProjectDetail project={project} refresh={fetchDetails} />
                            )
                            :
                            (
                                connectionStatus === "LOADING" ?
                                    (
                                        <ItemLoader text="Fetching details" />
                                    )
                                    :
                                    (
                                        connectionStatus === "CONNECTED" ?
                                            (
                                                <SetupProject project={project} refresh={fetchDetails} />
                                            )
                                            :
                                            (
                                                <ConnectGithub />
                                            )
                                    )
                            )
                    )
            }
        </Stack>
    )
}

export function ConnectGithub() {
    const { token } = useSelector((state: any) => state.user);

    return (
        <Stack w={'full'} spacing={6} align={'center'} justify={'center'} h={'md'}>
            <Heading fontSize={'3xl'}>Connect your Github Account</Heading>

            <Stack w={'full'} align={'center'} justify={'center'}>
                <Text color={'#b6b6b6c4'}>Please connect your github account to select repository that you want to attach with this project.</Text>
                <VASMButton
                    isLoading={typeof token?.accessToken !== 'string'}
                    leftIcon={<FaGithub fontSize={'22px'} />}
                    py={5}
                    w={'sm'}
                    fontWeight={'400'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={() => window.location.replace(`${API_HOST}/thirdparty/auth/connect/github?token=${token.accessToken}`)}
                >
                    Connect Github
                </VASMButton>
            </Stack>
        </Stack>
    )
}

export function SetupProject({ project, refresh }: { project: any, refresh: () => void }) {
    const toast = useToast();
    const [saving, setSaving] = useState<boolean>(false);
    const [repo, setRepo] = useState<string>("");

    async function handleSaveClick() {
        if (repo === "") return toast({
            position: 'bottom-right',
            status: 'error',
            title: `Please select a repository first!`,
            duration: 5000,
            isClosable: true
        });

        setSaving(true);
        const req = await POST(`/agent/projects/${project.id}/setup`, {
            repo
        });
        setSaving(false);

        if (req === undefined) return toast({
            position: 'bottom-right',
            status: 'error',
            title: `An error occured while setting up project repository.`,
            description: 'Please reload the page and try again, If the error persist, Contact us at support@vasm.dev',
            duration: 5000,
            isClosable: true
        });
        if (req.status !== 'success') return toast({
            position: 'bottom-right',
            status: 'error',
            title: req.message,
            duration: 5000,
            isClosable: true
        });

        refresh();
        setRepo("");

        toast({
            position: 'bottom-right',
            status: 'success',
            title: 'Project repository setup successfully!',
            duration: 5000,
            isClosable: true
        });
    }

    return (
        <Stack w={'full'} spacing={6} align={'center'} justify={'center'} h={'md'}>
            <Heading fontSize={'3xl'}>Select your Repository</Heading>

            <Stack w={'5xl'} spacing={1} align={'center'} justify={'center'}>
                <AllRepoDropdown onSelect={value => setRepo(value)} />
                <Button
                    mt={5}
                    px={16}
                    size={'md'}
                    bg={'blue.500'}
                    w={'fit-content'}
                    isLoading={saving}
                    loadingText={'Saving'}
                    onClick={handleSaveClick}
                    _hover={{ bg: 'blue.600' }}
                    _active={{ bg: 'blue.700' }}
                >
                    Save
                </Button>
            </Stack>
        </Stack>
    )
}

export function ShowStatus({ status }: { status: "INIT" | "QUEUED" | "BUILDING" | "SUCCESS" | "ERROR" }) {
    const colors = {
        "INIT": "white",
        "QUEUED": "gray",
        "BUILDING": "orange",
        "SUCCESS": "green",
        "ERROR": "red"
    }
    return (
        <GrStatusGoodSmall color={colors[status]} fontSize={'12px'} />
    )
}

export function ProjectDetail({ project, refresh }: { project: any, refresh: () => void }) {
    const toast = useToast();
    const [building, setBuilding] = useState<boolean>(false);

    const statusText: Record<string, string> = {
        "INIT": "Not built yet",
        "QUEUED": "In Queue",
        "BUILDING": "Building",
        "SUCCESS": "Online 🟢",
        "ERROR": "Offline 🔴"
    }

    async function handleBuildClick() {
        setBuilding(true);
        const req = await POST(`/agent/projects/${project.id}/build`, {});
        setBuilding(false);
        if (req === undefined) return toast({
            position: 'bottom-right',
            status: 'error',
            title: `An error occured while trying to build your project.`,
            description: 'Please refresh the page and re-try, If the error persists contact us at support@vasm.dev',
            duration: 5000,
            isClosable: true
        });

        if (req.status !== 'success') return toast({
            position: 'bottom-right',
            status: 'error',
            title: `An error occured while trying to build your project.`,
            description: 'Please refresh the page and re-try, If the error persists contact us at support@vasm.dev',
            duration: 5000,
            isClosable: true
        });

        refresh();

        return toast({
            position: 'bottom-right',
            status: 'success',
            title: `Your project is being built`,
            description: 'You can refresh the page to get the updated status.',
            duration: 5000,
            isClosable: true
        });
    }

    return (
        <Stack w={'full'} spacing={4}>
            <Flex w={'full'} justify={'space-between'} align={'center'}>
                <Flex gap={2}>
                    <Heading fontSize={'3xl'}>{project.name}</Heading>
                    <ShowStatus status={project.status} />
                </Flex>

                <Button
                    isLoading={project.status === 'BUILDING' || project.status === 'QUEUED' || building}
                    loadingText={'Building...'}
                    w={'36'}
                    background={'blue.500'}
                    _hover={{
                        background: 'blue.600'
                    }}
                    _active={{
                        background: 'blue.700'
                    }}
                    onClick={handleBuildClick}
                >
                    Build
                </Button>
            </Flex>

            <Stack w={'full'} direction={'row'} justify={'space-between'} align={'flex-start'}>
                <Stack
                    flex={2}
                    w={'full'}
                    rounded={'xl'}
                    border={'1px solid #51515142'}
                    boxShadow={'0px 0px 14px -11px #a3a3a39c'}
                    _hover={{
                        boxShadow: '0px 0px 14px -8px #a3a3a39c',
                    }}
                    _active={{
                        boxShadow: '0px 0px 14px -6px #a3a3a39c'
                    }}
                    px={6}
                    py={8}
                    spacing={6}
                >
                    <Stack w={'full'} spacing={0}>
                        <Heading fontSize={'sm'} color={'#b6b6b6c4'}>Status:</Heading>
                        <Text fontSize={'sm'} fontWeight={400} color={'#93939370'}>{statusText[project.status]}</Text>
                    </Stack>
                    <Stack w={'full'} spacing={0}>
                        <Heading fontSize={'sm'} color={'#b6b6b6c4'}>Last Built At:</Heading>
                        <Text fontSize={'sm'} fontWeight={400} color={'#93939370'}>{project.lastBuildAt ? new Date(project.lastBuildAt).toDateString() : 'Not built yet'}</Text>
                    </Stack>
                    <Stack w={'full'} spacing={0}>
                        <Heading fontSize={'sm'} color={'#b6b6b6c4'}>Repository Name:</Heading>
                        <Text fontSize={'sm'} fontWeight={400} color={'#93939370'}>{project.repo_name}</Text>
                    </Stack>
                    <Stack w={'full'} spacing={0}>
                        <Heading fontSize={'sm'} color={'#b6b6b6c4'}>Repository URL:</Heading>
                        <Text fontSize={'sm'} fontWeight={400} color={'#93939370'}>{project.misc.svn_url}</Text>
                    </Stack>
                    <Stack w={'full'} spacing={0}>
                        <Heading fontSize={'sm'} color={'#b6b6b6c4'}>Created At:</Heading>
                        <Text fontSize={'sm'} fontWeight={400} color={'#93939370'}>{new Date(project.createdAt).toDateString()}</Text>
                    </Stack>
                </Stack>
                <Stack
                    flex={4}
                    h={'xl'}
                    w={'full'}
                >
                    {
                        project.deployment_url && project.deployment_url !== "" && project.status === 'SUCCESS' ?
                            (
                                <Iframe
                                    src={`https://${project.deployment_url}`}
                                    title="Preview"
                                    width="100%"
                                    height="100%"
                                    onClick={() => window.open(`https://${project.deployment_url}`, '_blank')}
                                />
                            )
                            :
                            (
                                <Stack
                                    h={'xl'}
                                    w={'full'}
                                    rounded={'xl'}
                                    border={'1px solid #51515142'}
                                    boxShadow={'0px 0px 14px -11px #a3a3a39c'}
                                    _hover={{
                                        boxShadow: '0px 0px 14px -8px #a3a3a39c',
                                    }}
                                    _active={{
                                        boxShadow: '0px 0px 14px -6px #a3a3a39c'
                                    }}
                                    px={6}
                                    py={8}
                                    justify={'center'}
                                    align={'center'}
                                >
                                    {
                                        project.status === 'BUILDING' || project.status === 'QUEUED' ?
                                            (
                                                <>
                                                    <Spinner color="#93939370" />
                                                    <Text fontSize={'sm'} color={'#93939370'}>Project is being built...</Text>
                                                </>
                                            )
                                            :
                                            (
                                                project.status === 'ERROR' ?
                                                    (
                                                        <Text fontSize={'sm'} color={'#93939370'}><span style={{ color: 'white' }}>☹️</span> Error occured while building your project</Text>
                                                    )
                                                    :
                                                    (
                                                        <Text fontSize={'sm'} color={'#93939370'}>Project preview will appear here once it is successfully built</Text>
                                                    )
                                            )
                                    }
                                </Stack>
                            )
                    }
                </Stack>
            </Stack>
        </Stack>
    );
}