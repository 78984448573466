import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { POST } from "../../utils/api-client.util";

export default function CreateProjectModal({ isOpen, onOpen, onClose, updateList }: { isOpen: boolean, onOpen: () => void, onClose: () => void, updateList: () => void }) {
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string>("");

    async function handleCreateClick() {
        if (projectName === "") return toast({
            position: 'bottom-right',
            status: 'error',
            title: 'Enter project name to create...',
            duration: 5000,
            isClosable: true
        });

        setLoading(true);
        const { data } = await POST('/agent/projects/create', {
            name: projectName
        });
        setLoading(false);

        if (data.status === 'error') return toast({
            position: 'bottom-right',
            status: 'error',
            title: data.message,
            duration: 5000,
            isClosable: true
        });

        toast({
            position: 'bottom-right',
            status: 'success',
            title: 'Project created successfully',
            duration: 5000,
            isClosable: true
        });

        onClose();
        updateList();
        setProjectName("");
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay
                backdropFilter={'blur(8px)'}
            />
            <ModalContent background={'black'} rounded={'xl'}
                border={'1px solid #51515142'}
                boxShadow={'0px 0px 14px -11px #a3a3a39c'}>
                <ModalHeader>Create new Project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Input
                        px={4}
                        py={6}
                        placeholder='Project name'
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        w={'full'}
                        bg={'blue.500'}
                        color={'white'}
                        _hover={{
                            bg: 'blue.600',
                        }}
                        _active={{
                            bg: 'blue.700',
                        }}
                        onClick={handleCreateClick}
                        isLoading={loading}
                    >
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}