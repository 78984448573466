// authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api-client.util';
import { loginSuccess, logout, updateToken } from './user-reducer';
import axios from 'axios';
import { API_HOST } from '../constants';

export const checkAuthStatus: any = createAsyncThunk(
    'auth/validate',
    async (_, { dispatch }) => {
        const authString = localStorage.getItem('auth');
        const auth = JSON.parse(authString || '{}');

        if (auth && auth.id) {
            dispatch(loginSuccess(auth));
        } else {
            dispatch(logout());
        }
    }
);

export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (_, { dispatch }) => {
        const authString = localStorage.getItem('auth');
        let auth = JSON.parse(authString || '{}');
        try {
            const { data } = await axios.post(`${API_HOST}/auth/token/access`, {}, {
                headers: {
                    Authorization: `Bearer ${auth?.token?.refreshToken}`
                },
                validateStatus: () => true
            });
            if (data.status === 'success') {
                dispatch(updateToken(data.data.accessToken));
            } else {
                dispatch(logout());
                throw new Error(data.message);
            }
        } catch (error) {
            console.log('Failed to refresh token', error);
            dispatch(logout());
            throw error;
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkAuthStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(checkAuthStatus.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(checkAuthStatus.rejected, (state, action) => {
                state.status = 'failed';
            })
            .addCase(refreshToken.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(refreshToken.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.status = 'failed';
            });
    }
});

export default authSlice.reducer;