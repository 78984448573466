import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    useToast,
    Checkbox,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import axios from 'axios';
import { API_HOST } from '../constants';
import IAPIResponse from '../types/IAPIResponse';

export default function SignUp() {
    const navigate = useNavigate();
    const toast = useToast();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [form, setForm] = useState<{ username: string, password: string }>({
        username: "",
        password: ""
    });

    useEffect(() => {
        const authString = localStorage.getItem("auth");
        const auth = JSON.parse(authString === "" || authString === null ? "{}" : authString);
        if (auth !== null && auth !== undefined && auth.id !== undefined) navigate('/');
    }, []);

    async function handleSignUp() {
        if (form.password === "" || form.username === "") return toast({
            position: 'bottom-right',
            status: 'error',
            title: 'Please fill in all the fields first!',
            duration: 5000,
            isClosable: true
        });

        setLoading(true);
        const { data }: { data: IAPIResponse } = await axios.post(`${API_HOST}/auth/signin`, {
            username: form.username,
            password: form.password
        }, {
            validateStatus: () => true
        });
        setLoading(false);
        console.log(data);

        if (data.status !== "success") return toast({
            position: 'bottom-right',
            status: 'error',
            title: data.message,
            duration: 9000,
            isClosable: true
        });

        if (data.data?.UNVERIFIED === true) return toast({
            position: 'bottom-right',
            status: 'success',
            title: 'Please verify your account!',
            description: `We've sent you a verification link to your registered email address, Please use it to verify yourself in order to sign in`,
            duration: 86400
        });

        localStorage.setItem("auth", JSON.stringify(data.data));

        toast({
            position: 'bottom-right',
            status: 'success',
            title: 'Logged in successfully!',
            duration: 9000,
            isClosable: true
        });
        navigate('/');
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'} textAlign={'center'}>
                        Sign in with your account
                    </Heading>
                    <Text fontSize={'lg'} color={'rgba(255,255,255,0.6)'}>
                        or create a <Link as={ReactLink} to={'/sign-up'} color={'blue.400'}>new account</Link>
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    boxShadow={'0px 0px 14px -10px #b9b9b9b0'}
                    borderWidth={'0.1px'}
                    borderColor={'rgba(255,255,255,0.05)'}
                    px={6}
                    py={14}
                >
                    <Stack spacing={4} w={'sm'}>

                        <FormControl id="email" isRequired>
                            <FormLabel fontSize={'14px'}>Username</FormLabel>
                            <Input type="text" placeholder='ijoe' color={'rgba(255,255,255,0.8)'} onChange={(e) => setForm({ ...form, username: e.target.value })} />
                        </FormControl>

                        <FormControl id="password" isRequired>
                            <FormLabel fontSize={'14px'}>Password</FormLabel>
                            <InputGroup>
                                <Input type={showPassword ? 'text' : 'password'} placeholder='*********' color={'rgba(255,255,255,0.8)'} onChange={(e) => setForm({ ...form, password: e.target.value })} />
                                <InputRightElement h={'100%'} marginEnd={'2px'}>
                                    <Button
                                        size={'xs'}
                                        rounded={'full'}
                                        variant={'ghost'}
                                        onClick={() => setShowPassword((showPassword) => !showPassword)}>
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>

                        <Stack spacing={3} pt={5}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                <Text></Text>
                                <Link as={ReactLink} to={'/forgot-password'} color={'blue.400'}>Forgot password?</Link>
                            </Stack>

                            <Button
                                onClick={handleSignUp}
                                isLoading={isLoading}
                                loadingText="Logging in to your account..."
                                size="lg"
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}>
                                Login
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    )
}