import { Button, Flex, Heading, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical, BsChatSquareQuote } from 'react-icons/bs';
import { RiShutDownLine, RiRestartLine, RiFileShredLine, RiLogoutCircleRLine } from 'react-icons/ri';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthStatus } from "../../store/auth-reducer";
import LoadingOverlay from "../overlays/loading-overlay";
import VASMButton from "../buttons/vasm-button";
import { logout } from "../../store/user-reducer";

export default function Layout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [overlay, showOverlay] = useState<boolean>(false);
    const { status } = useSelector((state: any) => state.auth);
    const { isAuthenticated } = useSelector((state: any) => state.user);

    useEffect(() => {
        dispatch(checkAuthStatus());
    }, [dispatch]);

    function handleLogoutClick() {
        dispatch(logout());
    }

    useEffect(() => {
        showOverlay(true);
        if (status === 'idle') {
            if (isAuthenticated) {
                showOverlay(false);
                console.log('auth', isAuthenticated, status);
            } else {
                navigate('/');
                showOverlay(false);
            }
        }
    }, [status, isAuthenticated]);

    return (
        <Stack w={'full'} h={'full'} minH={'100vh'}>
            <LoadingOverlay show={overlay} />
            <Stack w={'full'} direction={'row'} px={8} py={4} borderBottom={'1px solid #8383833b'} justify={'space-between'} align={'center'}>
                <Heading
                    fontSize={'lg'}
                    _hover={{ cursor: 'pointer' }}
                    _active={{ cursor: 'pointer' }}
                    onClick={() => navigate('/workspace')}
                >
                    VASM Workspace
                </Heading>
                <VASMButton
                    leftIcon={<RiLogoutCircleRLine size={'20px'} color={'rgb(113,35,35)'} />}
                    onClick={handleLogoutClick}
                >
                    Logout
                </VASMButton>
            </Stack>

            <Stack px={14} py={5}>
                <Outlet />
            </Stack>
        </Stack>
    )
}