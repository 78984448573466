import {
  ChakraProvider
} from "@chakra-ui/react"
import VasmRouter from "./router"
import theme from "./theme"
import { Provider } from "react-redux"
import store from "./store"

export const App = () => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <VasmRouter />
    </ChakraProvider>
  </Provider>
)
