import axios from "axios";
import { POST } from "../utils/api-client.util";
import { API_HOST } from "../constants";

// Initial state
const initialState = {
    id: null,
    username: null,
    email: null,
    token: {
        refreshToken: null,
        accessToken: null
    },
    isAuthenticated: false
};

// Action types
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT = 'LOGOUT';
const UPDATE_TOKEN = 'UPDATE_TOKEN';

// Reducer
function userReducer(state = initialState, action: any) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                username: action.payload.username,
                email: action.payload.email,
                token: {
                    refreshToken: action.payload.token.refreshToken,
                    accessToken: action.payload.token.accessToken
                },
                isAuthenticated: true
            };
        case LOGOUT:
            return initialState;
        case UPDATE_TOKEN:
            return {
                ...state,
                token: {
                    ...state.token,
                    accessToken: action.payload.accessToken
                }
            };
        default:
            return state;
    }
}

// Action creators
const loginSuccess = (userData: any) => ({
    type: LOGIN_SUCCESS,
    payload: userData
});

const logout = () => {
    (async () => {
        const authString = localStorage.getItem("auth");
        const auth = JSON.parse(authString === "" || authString === null ? "{}" : authString);

        if (auth === null || auth === undefined || auth.id === undefined) return;

        await axios.post(`${API_HOST}/auth/logout`, {}, {
            headers: { Authorization: auth?.token?.accessToken },
            validateStatus: () => true
        });
    })()
    localStorage.removeItem('auth');

    setTimeout(() => {
        window.location.reload();
    }, 1000);
    return {
        type: LOGOUT
    };
}

const updateToken = (accessToken: any) => ({
    type: UPDATE_TOKEN,
    payload: { accessToken }
});

export { userReducer, loginSuccess, logout, updateToken };