import {
    Flex,
    Stack,
    Heading,
    IconButton,
    Text,
    SimpleGrid,
    Box,
    Button,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    ModalCloseButton,
    Input,
    Spinner
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useNavigate, Link as ReactLink } from 'react-router-dom';
import { GET } from '../utils/api-client.util';
import { useSelector } from 'react-redux';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { IoMdTime, IoMdAdd } from "react-icons/io";
import { FaGithub, FaBoxOpen } from "react-icons/fa";
import VASMButton from '../components/buttons/vasm-button';
import CreateProjectModal from '../components/modal/create-project-modal';
import ItemLoader from '../components/overlays/item-loader';
import { HiOutlineRefresh } from "react-icons/hi";

export default function Dashboard() {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [projects, setProjects] = useState<{
        items: any[], total: number, totalPages: number, currentPage: number, limit: number, loading: boolean
    }>({ items: [], total: 0, totalPages: 1, currentPage: 1, limit: 18, loading: true });

    useEffect(() => {
        (async () => {
            await fetchList();
        })()
    }, [projects.currentPage, projects.limit]);

    async function fetchList() {
        setProjects({ ...projects, loading: true });
        const response = await GET(`agent/projects/list?page=${projects.currentPage}&limit=${projects.limit}`, true);
        if (response.status !== "success" || response === undefined) {
            setProjects({ items: [], total: 0, totalPages: 0, currentPage: 0, limit: 18, loading: false });
        }
        else {
            setProjects({ ...response.data, loading: false });
        }
    }

    function handlePrevClick() {
        setProjects({ ...projects, currentPage: projects.currentPage - 1 });
    }

    function handleNextClick() {
        setProjects({ ...projects, currentPage: projects.currentPage + 1 });
    }

    async function handleRefreshClick() {
        if (projects.currentPage !== 1) {
            setProjects({ ...projects, currentPage: 1, limit: projects.limit });
        } else {
            await fetchList();
        }
    }

    return (
        <Stack w={'full'} spacing={6}>
            <CreateProjectModal
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                updateList={fetchList}
            />
            <Flex
                justify={'space-between'}
                align={'center'}
            >
                <Flex align={'center'} gap={4}>
                    <Heading>Projects</Heading>
                    <Tooltip
                        label={'Add new Project'}
                        bg={'black'}
                        border={'1px solid rgba(255,255,255,0.1)'}
                        color={'#93939370'}
                        rounded={'lg'}
                        placement='right'
                    >
                        <IconButton
                            icon={<IoMdAdd color={'white'} />}
                            aria-label='Add new project'
                            size={'xs'}
                            rounded={'full'}
                            onClick={onOpen}
                        />
                    </Tooltip>
                </Flex>

                <Flex justify={'center'} align={'center'} gap={8}>
                    <IconButton
                        isLoading={projects.loading}
                        aria-label='Refresh Projects List'
                        icon={<HiOutlineRefresh fontSize={'20px'} />}
                        variant={'ghost'}
                        size={'sm'}
                        rounded={'full'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        colorScheme='black'
                        onClick={handleRefreshClick}
                    />
                    <Flex gap={6} justify={'center'} align={'center'}>
                        <IconButton
                            aria-label='Previous Page'
                            icon={<BsChevronLeft fontSize={'20px'} />}
                            variant={'ghost'}
                            size={'sm'}
                            rounded={'full'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            colorScheme='black'
                            isDisabled={projects.currentPage === 1}
                            onClick={handlePrevClick}
                        />
                        <Text fontSize={'lg'}>1</Text>
                        <IconButton
                            aria-label='Previous Page'
                            icon={<BsChevronRight fontSize={'20px'} />}
                            variant={'ghost'}
                            size={'sm'}
                            rounded={'full'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            colorScheme='black'
                            onClick={handleNextClick}
                            isDisabled={projects.totalPages === projects.currentPage}
                        />
                    </Flex>
                </Flex>
            </Flex>

            {
                projects.loading ? (
                    <ItemLoader text='Loading Projects' />
                )
                    :
                    (
                        projects.items.length > 0 ?
                            (
                                <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                                    {
                                        projects.items.map((p, i) => (
                                            <ProjectCard
                                                id={p.id}
                                                date={new Date(p.createdAt).toDateString()}
                                                name={p.name}
                                                url={p.misc.svn_url || 'Not setup'}
                                                key={i}
                                            />
                                        ))
                                    }
                                </SimpleGrid>

                            )
                            :
                            (
                                <NoProjectParagraph onOpen={onOpen} />
                            )
                    )
            }
        </Stack>
    )
}

export function NoProjectParagraph({ onOpen }: { onOpen: () => void }) {
    return (
        <Stack w={'full'} justify={'center'} align={'center'} pt={16} spacing={4}>
            <FaBoxOpen color={'white'} fontSize={'72px'} />
            <Heading fontSize={'2xl'}>Your workspace is empty!</Heading>
            <Text color={'#93939370'} fontSize={'sm'}>Looks like you haven’t started any projects. Ready to get creative?</Text>
            <VASMButton
                leftIcon={<IoMdAdd color={'white'} fontSize={'16px'} />}
                onClick={onOpen}
            >Start a new Project</VASMButton>
        </Stack>
    )
}

export function ProjectCard(
    {
        id,
        name,
        date,
        url
    }: { id: string, name: string, date: string, url: string }
) {
    return (
        <Stack
            rounded={'xl'}
            border={'1px solid #51515142'}
            boxShadow={'0px 0px 14px -11px #a3a3a39c'}
            _hover={{
                boxShadow: '0px 0px 14px -8px #a3a3a39c',
                cursor: 'pointer'
            }}
            _active={{
                boxShadow: '0px 0px 14px -6px #a3a3a39c'
            }}
            padding={5}
            spacing={4}
            as={ReactLink}
            to={`/workspace/project/${id}`}
        >
            <Flex w={'full'} justify={'space-between'} align={'center'}>
                <Text fontSize={'xs'} color={'#93939370'}>{id}</Text>

                <Flex direction={'row'} gap={1} justify={'center'} align={'center'}>
                    <IoMdTime color={'#93939370'} fontSize={'14px'} />
                    <Text fontSize={'xs'} color={'#93939370'}>{date}</Text>
                </Flex>
            </Flex>
            <Heading fontSize={'2xl'}>{name}</Heading>

            <Flex direction={'row'} gap={2} justify={'start'} align={'center'}>
                <FaGithub color={'#93939370'} fontSize={'16px'} />
                <Text fontSize={'xs'} color={'#93939370'}>{url}</Text>
            </Flex>
        </Stack>
    )
}