// theme.ts
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    styles: {
        global: {
            body: {
                bg: 'black',
                color: 'white',
                fontFamily: 'Rubik, sans-serif',
            },
            '*': {
                transition: '0.5s all',
            },
            '::-webkit-scrollbar': {
                width: '3px',
            },
            '::-webkit-scrollbar-track': {
                background: 'black',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#505050',
                borderRadius: '24px',
            },
        },
    },
    fonts: {
        heading: 'Montserrat, sans-serif',
        body: 'Montserrat, sans-serif',
    },
    colors: {
        black: {
            50: '#f2f2f2',
            100: '#d9d9d9',
            200: '#bfbfbf',
            300: '#a6a6a6',
            400: '#8c8c8c',
            500: '#737373',
            600: '#595959',
            700: '#404040',
            800: '#262626',
            900: '#0d0d0d',
        },
    },
});

export default theme;