// api-client.util.js

import axios from 'axios';
import { stringify } from 'qs';
import { API_HOST } from '../constants';
import store from '../store';
import { refreshToken } from '../store/auth-reducer';

const api = axios.create({
    baseURL: API_HOST,
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: {
        serialize: (params) => stringify(params, { arrayFormat: 'repeat' })
    }
});

// Request interceptor
api.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const token = state.user.token?.accessToken;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                await store.dispatch(refreshToken());
                const state = store.getState();
                const newToken = state.user.token?.accessToken;

                if (newToken) {
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    return api(originalRequest);
                } else {
                    console.log('Session expired. Please login again')
                }
            } catch (refreshError) {
                console.log('refreshError', refreshError);
            }
        }

        return Promise.reject(error);
    }
);

// API methods
export const GET = async (endpoint: string, params = {}) => {
    try {
        const response = await api.get(endpoint, { params });
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

export const POST = async (endpoint: string, data = {}) => {
    try {
        const response = await api.post(endpoint, data);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

export const PUT = async (endpoint: string, data = {}) => {
    try {
        const response = await api.put(endpoint, data);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

export const DELETE = async (endpoint: string) => {
    try {
        const response = await api.delete(endpoint);
        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

const handleApiError = (error: any) => {
    if (error.response) {
        console.log("Data:", error.response.data);
        console.log("Status:", error.response.status);
        console.log("Headers:", error.response.headers);
    } else if (error.request) {
        console.log("Request:", error.request);
    } else {
        console.log("Error:", error.message);
    }
    console.log('API ERROR: ', error);
};

export default api;