import { Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";

export default function LoadingOverlay({ show }: { show: boolean }) {
    return (
        <Stack
            w={'full'}
            h={'full'}
            justify={'center'}
            align={'center'}
            position={'absolute'}
            zIndex={99999999999}
            backdropFilter={'blur(10px)'}
            style={!show ? { display: 'none' } : {}}
        >
            <Flex
                align={'center'}
                gap={3}
            >
                <Spinner
                    size={'md'}
                />
                <Text>Loading</Text>
            </Flex>
        </Stack>
    )
}