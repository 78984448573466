// store.js

import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './user-reducer';
import authReducer from './auth-reducer';

const store = configureStore({
    reducer: {
        user: userReducer,
        auth: authReducer
    },
});

export default store;