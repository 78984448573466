import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Spinner,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../store/user-reducer';
import { refreshToken } from '../store/auth-reducer';
import { UnknownAction } from 'redux';

export default function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const refresh = searchParams.get('refresh');
        const authString = localStorage.getItem("auth");
        const auth = JSON.parse(authString === "" || authString === null ? "{}" : authString);

        if (auth === null || auth === undefined || auth.id === undefined) {
            navigate('/sign-in');
        } else {
            dispatch(loginSuccess(auth));
            if (refresh) {
                dispatch(refreshToken() as unknown as UnknownAction);
                searchParams.delete('refresh');
            }
            const rLink = location.pathname === "/" ? "/workspace" : location.pathname;
            navigate(rLink);
        }
    }, [searchParams]);

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <HStack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                    <Spinner size={'lg'} />
                    <Heading fontSize={'4xl'} textAlign={'center'}>
                        Redirecting
                    </Heading>
                </HStack>
            </Stack>
        </Flex>
    )
}