import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Spinner,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_HOST } from '../constants';
import IAPIResponse from '../types/IAPIResponse';

type VERIFICATION = "VERIFYING" | "NO_USER" | "MALFORMED_TOKEN" | "UNVERIFIED_TOKEN" | "EXPIRED" | "SERVER_ERROR" | "VERIFIED";

export default function Verify() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState<VERIFICATION>("VERIFYING");

    useEffect(() => {
        (async () => {
            const { data }: { data: IAPIResponse } = await axios.post(`${API_HOST}/auth/verify/${token}`, {}, {
                validateStatus: () => true
            });

            if (data.status === "error") {
                if (data.message.toLowerCase().includes("malformed"))
                    setStatus('MALFORMED_TOKEN')
                else if (data.message.toLowerCase().includes("no user"))
                    setStatus('NO_USER')
                else if (data.message.toLowerCase().includes("unverified"))
                    setStatus('UNVERIFIED_TOKEN')
                else if (data.message.toLowerCase().includes("expired"))
                    setStatus('EXPIRED')
                else
                    setStatus("SERVER_ERROR");
            }
            else {
                setStatus("VERIFIED");
            }

            setTimeout(() => {
                navigate('/');
            }, 3000);
        })()
    }, []);

    function ShowStatus({ status }: { status: VERIFICATION }) {
        switch (status) {
            case "VERIFYING":
                return (
                    <HStack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                        <Spinner size={'lg'} />
                        <Heading fontSize={'4xl'} textAlign={'center'}>
                            Verifying
                        </Heading>
                    </HStack>
                )
            case "EXPIRED":
                return (
                    <Stack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>
                            Your token expired ⌚
                        </Heading>
                        <Text fontSize={'xl'} color={'rgba(255,255,255,0.6)'}>
                            Please login to generate a new verification link.
                        </Text>
                    </Stack>
                )
            case "MALFORMED_TOKEN":
            case "UNVERIFIED_TOKEN":
            case "NO_USER":
            case "SERVER_ERROR":
                return (
                    <Stack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                        <Heading fontSize={'2xl'} textAlign={'center'}>
                            ❌ Malicious Token detected!
                        </Heading>
                        <Text fontSize={'lg'} color={'rgba(255,255,255,0.6)'}>
                            Please don't use unauthorized token to verify yourself.
                        </Text>
                    </Stack>
                )
            case "EXPIRED":
                return (
                    <Stack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>
                            Your token expired ⌚
                        </Heading>
                        <Text fontSize={'xl'} color={'rgba(255,255,255,0.6)'}>
                            Please login to generate a new verification link.
                        </Text>
                    </Stack>
                )
            default:
                return (
                    <HStack align={'center'} justify={'center'} alignItems={'center'} spacing={4}>
                        <Spinner size={'lg'} />
                        <Heading fontSize={'4xl'} textAlign={'center'}>
                            Verifying
                        </Heading>
                    </HStack>
                )
        }
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <ShowStatus
                    status={status}
                />
            </Stack>
        </Flex>
    )
}