import React, { useRef, useEffect } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface IframeProps extends BoxProps {
    src: string;
    title: string;
    height: string;
    width: string;
}
const Iframe = ({ src, title, width, height, ...props }: IframeProps) => {
    const iframeRef = useRef<any>(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', () => {
                try {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                    const style = iframeDoc.createElement('style');
                    style.textContent = `
            * { pointer-events: none !important; }
            html, body { overflow: auto !important; }
          `;
                    iframeDoc.head.appendChild(style);
                } catch (e) {
                    console.error('Cannot access iframe content:', e);
                }
            });
        }
    }, []);

    return (
        <Box
            {...props}
            width={width}
            height={height}
            overflow="hidden"
            position="relative"
        >
            <Box
                as="iframe"
                ref={iframeRef}
                src={src}
                title={title}
                width="100%"
                height="100%"
                rounded={'lg'}
                border={'1px solid #51515142'}
                boxShadow={'0px 0px 14px -11px #a3a3a39c'}
                _hover={{
                    boxShadow: '0px 0px 14px -8px #a3a3a39c',
                    cursor: 'pointer'
                }}
            />
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={1}
                rounded={'lg'}
                border={'1px solid #51515142'}
                boxShadow={'0px 0px 14px -11px #a3a3a39c'}
                _hover={{
                    boxShadow: '0px 0px 14px -8px #a3a3a39c',
                    cursor: 'pointer'
                }}
            />
        </Box>
    );
};

export default Iframe;