import { Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GET } from "../../utils/api-client.util";
import { FaBoxOpen } from "react-icons/fa";

export function AllRepoDropdown({ onSelect }: { onSelect?: (value: string) => void }) {
    const toast = useToast();
    const [repos, setRepos] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selected, setSelected] = useState("");
    const { onOpen, isOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (selected !== "") {
            onSelect && onSelect(selected);
        }
    }, [selected]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            const request = await GET('/agent/github/repos');
            setLoading(false);
            if (request.status !== 'success' || request === undefined) return toast({
                position: 'bottom-right',
                status: 'error',
                title: `An error occured while fetching repos`,
                duration: 5000,
                isClosable: true
            });

            setRepos(request.data.repositories);
        })()
    }, []);

    return (
        <Popover placement="bottom" isLazy onOpen={onOpen} isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
                <Button
                    fontWeight={400}
                    bg={'transparent'}
                    rightIcon={<BsChevronDown />}
                    border={'1px solid #8383833b'}
                    justifyContent={'space-between'}
                    _hover={{ bg: '#21212142' }}
                    w={'full'}
                >
                    {selected === "" ? "All Repositories" : selected}
                </Button>
            </PopoverTrigger>
            <PopoverContent w="5xl" bg={'black'} maxH={'md'} overflowY={'scroll'} boxShadow={'0px 0px 14px -10px #b9b9b9b0'}
                borderWidth={'0.1px'}
                borderColor={'#8383833b'} _focus={{ boxShadow: 'none' }}>
                <PopoverBody w={'full'}>
                    <Stack w={'full'}>
                        {
                            loading ? (
                                <Button
                                    variant="ghost"
                                    leftIcon={<Spinner />}
                                    gap={2}
                                    fontWeight="normal"
                                    fontSize="sm"
                                    isDisabled
                                    size={'sm'}
                                >
                                    Loading
                                </Button>
                            )
                                :
                                (
                                    repos.length > 0 ? (
                                        <>
                                            {
                                                repos.map((r: any, i: number) => (
                                                    <Button
                                                        variant="ghost"
                                                        gap={2}
                                                        fontWeight="normal"
                                                        fontSize="sm"
                                                        size={'sm'}
                                                        onClick={() => {
                                                            setSelected(r.full_name);
                                                            onClose()
                                                        }}
                                                        key={i}
                                                    >
                                                        {r.full_name}
                                                    </Button>
                                                ))
                                            }
                                        </>
                                    )
                                        :
                                        (
                                            <Button
                                                variant="ghost"
                                                leftIcon={<FaBoxOpen />}
                                                gap={2}
                                                fontWeight="normal"
                                                fontSize="sm"
                                                isDisabled
                                                size={'sm'}
                                            >
                                                No repositories available
                                            </Button>
                                        )
                                )
                        }
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover >
    )
}