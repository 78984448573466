import { Flex, Spinner, Text } from "@chakra-ui/react";

export default function ItemLoader({ text }: { text: string }) {
    return (
        <Flex color={'#93939370'} w={'full'} gap={6} justify={'center'} align={'center'} h={'15vh'}>
            <Spinner
                size={'md'}
            />
            <Text>{text}</Text>
        </Flex>
    )
}